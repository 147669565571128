import './App.css';
import Navbar from './Components/Navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './Views/Home';
import Login from './Views/User/Login';
import Test from './Views/Test';
import ItemCatogory from './Views/Inventory/ItemCatogory';
import User from './Views/User/User';
import MainBilling from './Views/Billing/MainBilling'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Customer from './Views/Customer/Customer';
import Supplier from './Views/Supplier/Supplier';
import Sales from './Views/Sales/Sales';
import SalesTabs from './Views/Sales/SalesTabs';
import Grn from './Views/GRN/Grn';
import Stock from './Views/Stock/Stock';
import Return from './Views/Return/Return';

function App() {
  return (
    <>
      <Navbar />
      <br />
      <div className='appStyle'>
        <Routes>
          <>
            <Route exact path='/User' element={<User />} />
            <Route exact path='/Supplier' element={<Supplier />} />
            <Route exact path='/Sales' element={<SalesTabs />} />
            <Route exact path='/Grn' element={<Grn />} />
            <Route exact path='/' element={<Home />} />
            <Route exact path='/Billing' element={<MainBilling />} />
            <Route exact path='/Customer' element={<Customer />} />
            <Route exact path='/Login' element={<Login />} />
            <Route exact path='/test' element={<Test />} />
            <Route exact path='/itemCategory' element={<ItemCatogory />} />
            <Route exact path='/Stock' element={<Stock />} />
            <Route exact path='/Return' element={<Return />} />
          </>

        </Routes>
        <ToastContainer
          position="bottom-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </>
  );
}

export default App;
