import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Modal, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const Return = () => {
  const [returns, setReturns] = useState([]);
  const [sales, setSales] = useState([]);
  const [selectedInvoiceNo, setSelectedInvoiceNo] = useState('');
  const [selectedQty, setSelectedQty] = useState('');
  const [selectedProductName, setSelectedProductName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [filteredInvoiceNos, setFilteredInvoiceNos] = useState([]);
  const [filteredProductNames, setFilteredProductNames] = useState([]);

  useEffect(() => {
    // Fetch return entries from backend using Axios
    axios.get('https://bmserver.ccsoftwares.com/Return/getAllReturns')
      .then(response => {
        setReturns(response.data);
      })
      .catch(error => {
        console.error('Error fetching return entries:', error);
      });

    // Fetch sales data from backend
    axios.get('https://bmserver.ccsoftwares.com/Sales')
      .then(res => {
        setSales(res.data);
        // Extract and set unique invoice numbers and product names for filtering
        const uniqueInvoiceNos = [...new Set(res.data.map(sale => sale.invoice_no))];
        const uniqueProductNames = [...new Set(res.data.map(sale => sale.product_name))];
        setFilteredInvoiceNos(uniqueInvoiceNos);
        setFilteredProductNames(uniqueProductNames);
      })
      .catch(error => {
        console.error('Error fetching sales data:', error);
      });
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // Reset selected values
    setSelectedInvoiceNo('');
    setSelectedProductName('');
  };

  const handleInvoiceNoChange = (event) => {
    setSelectedInvoiceNo(event.target.value);
  };

  const handleProductNameChange = (event) => {
    setSelectedProductName(event.target.value);
  };

  const handleCreateReturn = async () => {
    try {
        // Make sure selectedInvoiceNo and selectedProductName are not empty
        if (!selectedInvoiceNo || !selectedProductName) {
          console.error('Invoice number and product name are required.');
          return;
        }
    
    
        // Prepare data for POST request
        const requestData = {
          invoice_no: selectedInvoiceNo,
          product_name: selectedProductName,
          qty: selectedQty, // Convert returnQty to integer
          cashier : 'sample',
          returnCashier : (JSON.parse(localStorage.getItem("loggedDetails"))?.userName.toUpperCase()),
          store : JSON.parse(localStorage.getItem("loggedDetails")).shop
        };


        const newDataFormStock = {
          itemName: selectedProductName,
          itemCategories : 0,
          itemCategoryName : "-",
          itemCodeNew: "-",
          qty : selectedQty,
          store: JSON.parse(localStorage.getItem("loggedDetails")).shop ,
        }

   
        await axios.post('https://bmserver.ccsoftwares.com/Return/createReturn', requestData).then(res => {
            axios.post("https://bmserver.ccsoftwares.com/Stock/incStock",newDataFormStock).then(res =>{
              //console.log(res.status)
              setModalOpen(false)
            }).catch(e =>{
                alert(e)
            })
        }).catch (err => {
            alert(err)
        })
    
        // Optionally, you can handle success or show a message to the user
      } catch (error) {
        console.error('Error creating return entry:', error);
        // Optionally, you can handle the error or show a message to the user
      }


    
  };

  const handleInvoiceNoSearchChange = (event) => {
    const value = event.target.value;
    // Filter invoice numbers based on user input
    const filteredNos = sales.filter(sale => sale.invoice_no.includes(value));
    const uniqueFilteredNos = [...new Set(filteredNos.map(sale => sale.invoice_no))];
    setFilteredInvoiceNos(uniqueFilteredNos);
  };

  const handleProductNameSearchChange = (event) => {
    const value = event.target.value;
    // Filter product names based on user input
    const filteredNames = sales.filter(sale => sale.product_name.toLowerCase().includes(value.toLowerCase()));
    const uniqueFilteredNames = [...new Set(filteredNames.map(sale => sale.product_name))];
    setFilteredProductNames(uniqueFilteredNames);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpenModal} style={{ marginBottom: '20px' }}>Create Return</Button>

      <Modal open={modalOpen} onClose={handleCloseModal}>
  <Paper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, padding: '20px' }}>
    <TextField
      fullWidth
      label="Search Invoice No"
      variant="outlined"
      onChange={handleInvoiceNoSearchChange}
      style={{ marginBottom: '20px' }}
    />
    <FormControl fullWidth style={{ marginBottom: '20px' }}>
      <InputLabel>Select Invoice No</InputLabel>
      <Select
        value={selectedInvoiceNo}
        onChange={handleInvoiceNoChange}
      >
        {filteredInvoiceNos.map(invoiceNo => (
          <MenuItem key={invoiceNo} value={invoiceNo}>{invoiceNo}</MenuItem>
        ))}
      </Select>
    </FormControl>

    <TextField
      fullWidth
      label="Search Product Name"
      variant="outlined"
      onChange={handleProductNameSearchChange}
      style={{ marginBottom: '20px' }}
    />
    <FormControl fullWidth style={{ marginBottom: '20px' }}>
      <InputLabel>Select Product Name</InputLabel>
      <Select
        value={selectedProductName}
        onChange={handleProductNameChange}
      >
        {filteredProductNames.map(productName => (
          <MenuItem key={productName} value={productName}>{productName}</MenuItem>
        ))}
      </Select>
    </FormControl>

    <TextField
      fullWidth
      label="Return Quantity"
      variant="outlined"
      value={selectedQty}
      onChange={(event) => setSelectedQty(event.target.value)}
      type="number"
      style={{ marginBottom: '20px' }}
    />

    <Button variant="contained" onClick={handleCreateReturn} disabled={!selectedInvoiceNo || !selectedProductName || !selectedQty}>Create Return</Button>
  </Paper>
</Modal>

      <TableContainer component={Paper}>
        <Table>
        <TableHead>
            <TableRow>
              <TableCell>Invoice No</TableCell>
              <TableCell>Return Cashier</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Store</TableCell>
              <TableCell>Creation Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {returns.map(returnEntry => (
              <TableRow key={returnEntry._id}>
                <TableCell>{returnEntry.invoice_no}</TableCell>
                <TableCell>{returnEntry.returnCashier}</TableCell>
                <TableCell>{returnEntry.product_name}</TableCell>
                <TableCell>{returnEntry.qty}</TableCell>
                <TableCell>{returnEntry.store}</TableCell>
                <TableCell>{new Date(returnEntry.creation_date).toLocaleDateString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Return;
