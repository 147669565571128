import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid, TextField } from '@mui/material';
import {SyncLoader} from 'react-spinners'


function MainStoreSales() {


    const[sales,setSales] = useState([])
    const [search,setSearch] = useState('')
    const [loading,setLoading] = useState(true)
    const [waraTotal,setWaraTotal] = useState(0)

    var currentDate = new Date();
    currentDate.setDate(1);
    var startDateOfMonth = currentDate.toISOString().split('T')[0];
  
    var todayDate = new Date().toISOString().split('T')[0];
      const [startDate,setStartDate] = useState(startDateOfMonth)
      const [endDate,setEndDate] = useState(todayDate)


    useEffect(() => {
      
            
            axios.get("https://bmserver.ccsoftwares.com/Sales/warakapola").then(res => {
              //console.log(res.data)
              setSales(res.data)
              setLoading(false)
            })

            const data = {
              from_date : startDate,
              to_date : endDate
            }


            axios.post("https://bmserver.ccsoftwares.com/Sales/TotalSales",data).then(res => {
              if (res.data.total_sales) {
                res.data.total_sales_by_store.map((e,i) =>{
                  if(e.store === "Warakapola"){
                    setWaraTotal(e.total_sales)
                  }
                })
              }else{
                setWaraTotal(0)
              }
            })
            
    }, [endDate, startDate])


    const formatCurrency = (value) => {
      return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'LKR', // Change to your desired currency code
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
      }).format(value);
  };
    
    



  return (
    <>
    <div style={{backgroundColor  :'white',width : '100%',marginLeft : 10}}>
        <h1>Warakapola Sales</h1>
        <Grid container spacing={2}>

 
                <Grid item md={5} xs={12}>
                    <TextField
                        placeholder=''
                        type='date'
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Date from "
                        value={startDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => setStartDate(e.target.value)}
                        >
                    </TextField>
                </Grid>
                <Grid item md={5} xs={12}>
                    <TextField
                        placeholder=''
                        type='date'
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Date To "
                        value={endDate}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => setEndDate(e.target.value)}
                        >
                    </TextField>
                </Grid>
                <Grid item md={2} xs={12}>
                    <div style={{justifyContent : 'center',alignItems : 'center',textAlign : 'center',height : '100%',display : 'flex',fontWeight : 'bold',fontSize : 20}}>
                      Total :{formatCurrency(waraTotal)}
                    </div>
                </Grid>
                </Grid>
        <TextField id="standard-basic" label="Search by Invoice No / Product name" variant="standard" onChange={(e) => {setSearch(e.target.value)}} fullWidth autoComplete="off"/>
    </div>
    {loading ? (
      <div style={{display : 'flex',justifyContent : 'center',alignItems : 'center',height : '100vh'}}>
        <SyncLoader color="#1e1e1e" />
      </div>
    ) : (

      
      <div style={{display : 'flex',justifyContent : 'center',marginTop : 70}}>
    <TableContainer component={Paper}>
      <Table aria-label="sales table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Invoice No</TableCell>
            <TableCell>Product Name</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Cashier</TableCell>
            <TableCell>Gross Total</TableCell>
            <TableCell>Net Total</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Shop</TableCell>
            <TableCell>Bill Date /Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales.filter((element) => {
                          if (search === '') {
                              return element
                          }
                          else if ((element.invoice_no.toLowerCase()).includes(search.toLowerCase()) || (element.product_name.toLowerCase()).includes(search.toLowerCase()) ) {
                              return element
                              
                          }
                          
                      }).map((e, i) => (
            <TableRow key={i+1}>
              <TableCell>{i+1}</TableCell>
              <TableCell>{e.invoice_no}</TableCell>
              <TableCell>{e.product_name}</TableCell>
              <TableCell>{e.qty}</TableCell>
              <TableCell>{e.cashier}</TableCell>
              <TableCell>Rs.{e.gross_total}.00</TableCell>
              <TableCell>Rs.{e.net_total}.00</TableCell>
              <TableCell>Rs.{e.discount}.00</TableCell>
              <TableCell>{e.store}</TableCell>
              <TableCell>{e.bill_date.split('T')[0]} / {e.bill_time}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>
    )}



    </>


  )
}

export default MainStoreSales